<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Driver Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Driver Name</label
                    >
                    <v-text-field
                      disabled
                      outlined
                      placeholder="Driver Name"
                      v-model="driverName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0 mb-8">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Status</label
                    >
                    <label class="ml-3 d-block">{{
                      driverStatusResolved
                    }}</label>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="py-0">
                    <simple-table
                      table-title="Deliveries"
                      :headers="deliveryTable.headers"
                      :items="driver.deliveries"
                      :enabled-action-deactivate="false"
                      :enabled-action-delete="false"
                      :enabled-action-edit="false"
                      :enabled-action-go-to-item-page="true"
                      :show-create-new-item-button="false"
                      @goToItemPage="goToDeliveryItemPage"
                    ></simple-table>
                  </v-col>
                </v-row>

                <v-btn
                  @click="goToUser"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="shipDeliveryButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  style="margin-top: 30px"
                  color="#5e72e4"
                  >Manage User</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import SimpleTable from "@/components/Tables/SimpleTable.vue";

export default {
  name: "drivers-edit",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
    SimpleTable,
  },
  validations() {
    return {
      assignedDriver: { required },
      deliveryDate: { required },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      assignedDriver: null,
      driver: {
        status: "pending_approval",
        firstName: "",
        lastName: "",
        deliveries: [],
        userId: "",
      },
      deliveryTable: {
        headers: [
          {
            text: "Order #",
            align: "start",
            sortable: false,
            value: "orderReference",
          },
          { text: "Shop", value: "shop" },
          { text: "Delivery Date", value: "deliveryDate" },
          { text: "Status", value: "status" },
          { text: "Actions", value: "actions", sortable: false },
        ],
      },
      shipDeliveryButton: {
        isLoading: false,
      },
      rescheduleDeliveryButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
    driverName() {
      return `${this.driver.firstName} ${this.driver.lastName}`;
    },

    driverStatusResolved() {
      const obj = this.driverStatuses.find(
        (element) => element.key == this.driver.status
      );
      return obj.value;
    },
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    goToDeliveryItemPage(item) {
      this.$router.push(`/deliveries/edit/${item.id}`);
    },

    goToUser() {
      this.$router.push(`/users/edit/${this.driver.userId}`);
    },

    async rescheduleDelivery() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.rescheduleDeliveryButton.isLoading = true;

      const deliveryBody = {
        delivery_date: this.deliveryDate,
        status: "delivery_rescheduled",
      };

      if (this.assignedDriver) {
        deliveryBody.driver_id = this.assignedDriver;
      }

      this.$http
        .patch(
          `/deliveries/${this.id}`,
          deliveryBody,
          this.sessionStore.getHttpConfig
        )
        .then(async () => {
          this.$notify.success("Delivery updated successfully!");
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while updating the delivery. Please try again later or contact support."
          );
          console.error("Error while updating delivery: ", error);
        })
        .finally(() => {
          this.rescheduleDeliveryButton.isLoading = false;
        });
    },

    async shipDelivery() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.shipDeliveryButton.isLoading = true;

      const deliveryBody = {
        driver_id: this.assignedDriver,
        status: "in_transit",
      };

      if (this.deliveryDate) {
        deliveryBody.delivery_date = this.deliveryDate;
      }

      this.$http
        .patch(
          `/deliveries/${this.id}`,
          deliveryBody,
          this.sessionStore.getHttpConfig
        )
        .then(async () => {
          this.$notify.success("Delivery updated successfully!");
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while updating the delivery. Please try again later or contact support."
          );
          console.error("Error while updating delivery: ", error);
        })
        .finally(() => {
          this.shipDeliveryButton.isLoading = false;
        });
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.shipDeliveryButton.isLoading = true;

      const orderBody = {
        status: this.orderStatus,
      };

      this.$http
        .patch(
          `/order/${this.shopDetails.shopId}`,
          orderBody,
          this.sessionStore.getHttpConfig
        )
        .then(async () => {
          this.$notify.success("Order updated successfully!");
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while updating the order. Please try again later or contact support."
          );
          console.error("Error while updating order: ", error);
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },

    async submitReadyForDelivery() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const orderBody = {
        status: "review_and_assign_driver",
      };

      this.$http
        .patch(
          `/order/${this.shopDetails.shopId}`,
          orderBody,
          this.sessionStore.getHttpConfig
        )
        .then(async () => {
          this.$notify.success("Order updated successfully!");
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while updating the order. Please try again later or contact support."
          );
          console.error("Error while updating order: ", error);
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {
    this.$http
      .get(`/drivers/${this.id}`, this.sessionStore.getHttpConfig)
      .then((result) => {
        const driver = result.data.data[0];

        this.driver.status = driver.status;
        this.driver.userId = driver.user.id;

        this.driver.firstName = driver.user.firstname;
        this.driver.lastName = driver.user.lastname;

        this.driver.deliveries.splice(0);
        for (let i = 0; i < driver.deliveries.length; i++) {
          this.driver.deliveries.push({
            id: driver.deliveries[i].id,
            orderReference: driver.deliveries[i].order.order_reference,
            shop: driver.deliveries[i].order.shop.name,
            deliveryDate: driver.deliveries[i].delivery_date,
            status: driver.deliveries[i].status,
            // actions: ,
          });
        }
      })
      .catch((error) => {
        this.$notify.error(
          "An error occurred while loading the delivery details. Please try again later or contact support."
        );
        console.error("Error while fetching delivery details: ", error);
      });
  },
};
</script>
